import React, { useEffect } from "react";
import Base from "../core/Base";
import tallyErp9 from "../assets/tallyLogo.svg";
import biz from "../assets/biz.png";
import b1 from "../assets/b1.jpg";
import b2 from "../assets/b2.jpg";
import b3 from "../assets/b3.jpg";

import Unisoft from "../assets/Unisoft Logo.png";
import bgWhy from "../assets/bgWhy.jpg";
import Customized from "../assets/customized.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

import { Container, Row, Col, Carousel } from "react-bootstrap";
import ProductServiceCard from "../components/ProductServiceCard";
import WhyChoosseUs from "../components/WhyChoosseUs";
import LogoCarousel from "../components/LogoCarousel";
import MobileLogoCarousel from "../components/MobileLogoCarousel";
import Sugam from "../assets/sugam.png";
const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  window.scrollTo(0, 0);

  return (
    <Base>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={b2} alt="First slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={b1} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={b3} alt="Third slide" />
        </Carousel.Item>
      </Carousel>

      {/*  */}

      <Container fluid className="text-center" style={{ marginTop: "2em" }}>
        <h1 className="product-title">OUR PRODUCTS</h1>
      </Container>
      <Row style={{ marginTop: "3em" }}>
        <Col
          sm={12}
          lg={true}
          style={{ margin: "auto" }}
          className="text-center"
        >
          <ProductServiceCard
            aos="fade-right"
            img={Customized}
            title="Customized Software"
            desc="We help in designing, developing and implementation of the software as per your requirements. You can Scale the software as your business grows. "
          />
        </Col>
        <Col className="col-product text-center" sm={12} lg={true}>
          <ProductServiceCard
            aos="fade-left"
            img={tallyErp9}
            title="TallyPrime"
            desc="Tally is a product by Tally Solutions Pvt. Ltd which is an Indian MNC. It’s main product,TallyPrime is a software that handles accounting,"
          />
        </Col>
        <Col className="col-product text-center" sm={12} lg={true}>
          <ProductServiceCard
            aos="fade-left"
            img={Unisoft}
            title="Sahakar Samrat"
            desc="Software for Credit Co-Operative Society.It improves the staff productivity and efficiency and helps to manage the Patsanstha in a efficient way."
          />
        </Col>
        <Col sm={12} lg={true} className="text-center">
          <ProductServiceCard
            aos="fade-left"
            img={biz}
            title="BIZ ANALYST"
            desc="Biz Analyst TallyPrime App brings real time information & allows you to access your TallyPrime accounting data online"
          />
        </Col>
        <Col sm={12} lg={true} className="text-center">
          <ProductServiceCard
            aos="fade-left"
            img={Sugam}
            title="Sugam Tally Cloud"
            desc="Run your Tally from Anywhere, Anytime, Anydevice with Sugam Tally Cloud. A seamless Browser based experience of using Tally ERP/Prime."
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "2em" }}>
        <Col sm={12} lg={6}>
          <img data-aos="flip-left" src={bgWhy} alt="img" className="img-why" />
        </Col>
        <Col sm={12} lg={6}>
          <h1 data-aos="fade-down" className="text-center mb-3">
            WHY CHOOSE US
          </h1>
          <WhyChoosseUs
            aos="fade-left"
            icon="fa-inr"
            title="AFFORDABLE"
            desc="We charge highly competitive, affordable rates for all our services"
          />
          <WhyChoosseUs
            aos="fade-left"
            icon="fa-users"
            title="SUPPORT"
            desc="We provide the continual delivery of superior technical support"
          />
          <WhyChoosseUs
            aos="fade-left"
            icon="fa-eye"
            title="EXPERIENCE"
            desc="We have over 15+ Year of Experience"
          />
        </Col>
      </Row>
      <h1 className="text-center logo-home">Our Customer</h1>
      <div className="container mb-4">
        <MobileLogoCarousel />
        <LogoCarousel />
      </div>
    </Base>
  );
};

export default Home;
