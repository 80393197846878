import React from "react";
import Base from "../core/Base";

const Support = () => {
  window.scrollTo(0, 0);
  return (
    <Base>
      <div className="row">
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-laptop" aria-hidden="true"></i>
            <h4>Custom Software</h4>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            <h4>Tally Sales</h4>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-book" aria-hidden="true"></i>
            <h4>Sahakar Samrat</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
            <h4>Tally Updates / Renewals</h4>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-database" aria-hidden="true"></i>
            <h4>Tally Data Recovery</h4>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-cog" aria-hidden="true"></i>
            <h4>Tally Implementation</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-wrench" aria-hidden="true"></i>
            <h4>Tally Customization</h4>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="cards text-center">
            <i class="fa fa-users" aria-hidden="true"></i>
            <h4>Biz Analyst Sales</h4>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4 ">
          <div className="cards text-center">
            <i class="fa fa-usd" aria-hidden="true"></i>
            <h4>GST Hero Sales</h4>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default Support;
