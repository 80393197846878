import React from "react";

const WhyChoosseUs = ({ icon, title, desc, aos }) => {
  return (
    <div className="why-feature" data-aos={aos}>
      <span className="dot">
        <i class={`fa ${icon} feature-icon`}></i>
      </span>

      <div className="feature">
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default WhyChoosseUs;
