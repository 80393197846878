import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Base = ({ className = "", children }) => {
  return (
    <div>
      <Header />
      <a href="https://wa.me/919822375051" class="float">
        <i class="fa fa-whatsapp my-float" aria-hidden="true"></i>
      </a>
      <div className={className}>{children}</div>
      <Footer />
    </div>
  );
};

export default Base;
