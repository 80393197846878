import React from "react";
import logo1 from "../assets/logo1.png";
import logo2 from "../assets/logo2.png";
import logo3 from "../assets/logo3.png";
import logo4 from "../assets/logo4.png";
import logo5 from "../assets/logo5.png";
import logo6 from "../assets/logo6.png";
import logo7 from "../assets/logo7.png";
import logo8 from "../assets/logo8.png";
import logo9 from "../assets/logo9.png";
import logo10 from "../assets/logo10.png";
import logo11 from "../assets/logo11.png";
import logo12 from "../assets/logo12.png";
import logo13 from "../assets/logo13.png";
import logo14 from "../assets/logo14.png";
import logo15 from "../assets/logo15.png";
import logo16 from "../assets/logo16.png";
import logo17 from "../assets/logo17.png";
import logo18 from "../assets/logo18.png";
import logo19 from "../assets/logo19.png";
import logo20 from "../assets/logo20.png";
import logo21 from "../assets/logo21.png";
import logo22 from "../assets/logo22.png";
import logo23 from "../assets/logo23.png";
import logo24 from "../assets/logo24.png";

import { Carousel } from "react-bootstrap";

const MobileLogoCarousel = () => {
  return (
    <div className="mobile-logo">
      <Carousel indicators={false} interval="1500">
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo8} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo2} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo3} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo4} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo5} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo6} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo7} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo1} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo9} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo10} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo11} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo12} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo13} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo14} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo15} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo16} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo17} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo18} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo19} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo20} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo21} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>{" "}
        <Carousel.Item>
          <div className="row">
            <div className="col-4">
              <img className="d-block w-100" src={logo22} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo23} alt="First slide" />
            </div>
            <div className="col-4">
              <img className="d-block w-100" src={logo24} alt="First slide" />
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default MobileLogoCarousel;
