import React from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <img
          src={logo}
          height="50"
          className="d-inline-block align-top"
          alt="msolutions"
        />
        <p className="footer-links">
          <Link to="/" className="link-1">
            Home
          </Link>
          <Link to="/support">Support</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </p>

        <p className="footer-company-name">M ~ Solutions © 2020</p>
      </div>

      <div className="footer-center">
        <div>
          <i className="fa fa-map-marker"></i>
          <p>
            <span>Office No 21, 2nd Floor, Continental Chambers,</span> Near
            Sonal Hall, Erandwane, Karve Road <span>Pune 411004</span>
          </p>
        </div>

        <div>
          <i className="fa fa-phone"></i>
          <p>+91 98223 75051</p>
        </div>

        <div>
          <i className="fa fa-envelope"></i>
          <p>
            <a className="mail-link" href="mailto:info@msolutions.net.in">
              info@msolutions.net.in
            </a>
          </p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span>About the company</span>M ~ Solutions has provided solutions to
          various establishments including the finance, retail, automobile,
          garment, wholesale sectors.
        </p>
        <div className="footer-icons">
          <a href="https://www.facebook.com/msolutionspune/">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCVHUJ_l2ICSpU5QFk17y7ew">
            <i className="fa fa-youtube-play"></i>
          </a>
          <a href="https://www.linkedin.com/company/msolutionspune/">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="https://www.instagram.com/">
            <i className="fa fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className="copyright text-center">
        <h6 style={{ color: "#A9A9A9 " }}>
          Designed & Developed by{" "}
          <a style={{ color: "white" }} href="https://gratustech.com/">
            Gratus Tech
          </a>
        </h6>
      </div>
    </footer>
  );
};

export default Footer;
