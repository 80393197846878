import React from "react";
import { Card } from "react-bootstrap";

const ProductServiceCard = ({ img, title, desc, aos }) => {
  return (
    <Card data-aos={aos} style={{ height: "24rem" }} className="product-cards">
      <Card.Img className="card-img" variant="top" src={img} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductServiceCard;
