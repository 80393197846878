import React, { useState } from "react";
import Base from "../core/Base";
import { Row, Col, Spinner } from "react-bootstrap";
import ContactCard from "../components/ContactCard";
const Support = () => {
  const [load, setload] = useState(true);
  window.scrollTo(0, 0);
  return (
    <Base>
      <div className="support-bg">
        <div className="support-content text-center">
          <h2>Get in touch</h2>
          <p>
            Want to get in touch? We'd love to hear from you. Here's how you can
            reach us...
          </p>
        </div>
      </div>

      <Row>
        <Col>
          <ContactCard
            icon="fa-phone"
            desc="Just pick up the phone to chat with a us."
            href="tel:+919822375051"
            hrefDesc="+91 98223 75051"
          />
        </Col>
        <Col>
          <ContactCard
            icon="fa-envelope-o"
            desc="Email Us on"
            href="mailto:info@msolutions.net.in"
            hrefDesc="info@msolutions.net.in"
          />
        </Col>
        <Col>
          <ContactCard
            icon="fa-whatsapp"
            desc="Whatsapp Us on"
            href="https://wa.me/919822375051"
            hrefDesc="+91 98223 75051"
          />
        </Col>
      </Row>
      <h1 className="text-center"> Get Directions on Map</h1>
      <div className="container">
        {load && (
          <div className="text-center">
            <Spinner style={{ marginTop: "50px" }} animation="grow" />
          </div>
        )}
        <iframe
          onLoad={() => {
            setload(false);
          }}
          title="Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60548.09088847529!2d73.82740294008993!3d18.472074295489524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf8d162d9b77%3A0x9199d94bed7b6f43!2sM%20~%20Solutions!5e0!3m2!1sen!2sin!4v1597850394132!5m2!1sen!2sin"
          width="100%"
          height="600"
          frameborder="0"
          style={{ border: "0", display: `${load ? "none" : "block"}` }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </Base>
  );
};

export default Support;
