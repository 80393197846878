import React from "react";
import logo from "../logo.png";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
const currentTab = (history, path) => {
  if (history.location.pathname === path) {
    return { color: "#f6892d" };
  } else {
    return { color: "Black" };
  }
};

const Menu = ({ history }) => {
  return (
    <Navbar bg="light" expand="lg">
      <div className="container">
        <Link to="/">
          <img
            src={logo}
            height="50"
            // className="d-inline-block align-top"
            alt="msolutions"
          />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link>
              <Link
                style={currentTab(history, "/")}
                className="nav-link"
                to="/"
              >
                Home
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                style={currentTab(history, "/support")}
                className="nav-link"
                to="/support"
              >
                Support
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                style={currentTab(history, "/about")}
                className="nav-link"
                to="/about"
              >
                About Us
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                style={currentTab(history, "/contact")}
                className="nav-link"
                to="/contact"
              >
                Contact Us
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default withRouter(Menu);
